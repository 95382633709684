import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import Settings from "../Settings";

function NavBar(props) {
  return (
    <div className="navbar flex w-100 justify-between items-center pt-5 py-3 sm:pt-3 text-black dark:text-white">
      <HelpOutlineIcon sx={{'margin-left':'12px'}}
        onClick={() => {
          props.help(true);
        }}
      />
      <h1 className="text-3xl font-bold tracking-wider">GAME WORDLE</h1>
      <div className='w-9'></div>
    </div>
  );
}

export default NavBar;
