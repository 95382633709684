
function Footer() {
    return (
        <footer class="bg-white w-full flex  flex-col justify-between items-center p-4 sm:flex-row">
            <div>
                <span className="text-base text-gray-500">This website is not affiliated with the original Wordle game.</span>
            </div>
            <div className="mt-10 sm:mt-0 text-base text-gray-500 hover:text-gray-900">
                <a href="Privacy.html">Privacy policy</a>
            </div>            
        </footer>
    );
  }
  
  export default Footer;