import Box from '../Box'


function Content(props) {
    return (
      <>
          <div className=" bg-lime-200 w-full p-4 text-Black flex flex-col gap-2 justify-center items-center mt-5">
            <h1 className="text-xl font-bold tracking-wide">Wordle objective: Guess the 5 letter word</h1>
            <p class='lg:w-1/2 text-sm'>
                Your goal is to guess a secret five-letter word within a set number of tries. Submit five-letter guesses, and get feedback on correct letters in the right position (green), correct letters in the wrong position (yellow), or incorrect letters (gray). Use this feedback strategically to deduce the target word within the given guesses. The game tests your deduction, pattern recognition, and word association skills, challenging you to make informed guesses and solve the word puzzle.
            </p>
          </div>
          <h3 className="text-xl font-bold tracking-wide py-5">Examples</h3>
        <div className="flex gap-1 w-fit">
          <Box value="S" state="C" />
          <Box value="W" />
          <Box value="E" />
          <Box value="A" />
          <Box value="T" />
        </div>
        <p className="text-left text-sm sm:text-base py-2 opacity-75 w-fit">
          The letter <b>S</b> is in the word and in the correct spot.
        </p>
        <div className="flex gap-1 w-fit">
          <Box value="N" />
          <Box value="U" />
          <Box value="M" state="E" />
          <Box value="B" />
          <Box value="S" />
        </div>
        <p className="text-left text-sm sm:text-base py-2 opacity-75 w-fit">
          The letter <b>M</b> is in the word and in the correct spot.
        </p>
        <div className="flex gap-1 w-fit">
          <Box value="F" />
          <Box value="L" state="N" />
          <Box value="A" />
          <Box value="T" />
          <Box value="S" />
        </div>
        <p className="text-left text-sm sm:text-base py-2 opacity-75 w-fit">
          The letter <b>N</b> is in the word and in the correct spot.
        </p>

        <div className='mt-5 px-3 flex flex-col'>
            <h1 className="text-xl font-bold tracking-wide py-5">Frequently Asked Questions</h1>
            <div className='border-2 p-4 max-w-2xl w-full mb-3  rounded-lg'>
                <h1 className='text-lg  font-semibold tracking-wide text-left'>What skills does Wordle test?</h1>
                <p className='text-base text-left mt-2 text-gray-600'>The game is designed for quick play, offering a brief but engaging word-solving experience.</p>
            </div>
            <div className='border-2 p-4 max-w-2xl w-full mb-3  rounded-lg'>
                <h1 className='text-lg  font-semibold tracking-wide text-left'>How short is the game?</h1>
                <p className='text-base text-left mt-2 text-gray-600'>Wordle challenges deduction, pattern recognition, and word association skills as you work to make informed guesses.</p>
            </div>
            <div className='border-2 p-4 max-w-2xl w-full mb-3  rounded-lg'>
                <h1 className='text-lg  font-semibold tracking-wide text-left'>Can I play with friends?</h1>
                <p className='text-base text-left mt-2 text-gray-600'>While Wordle is typically a solo game, you can share your progress and compete informally by comparing the number of guesses needed to solve a word.</p>
            </div>
            <div className='border-2 p-4 max-w-2xl w-full mb-3  rounded-lg'>
                <h1 className='text-lg  font-semibold tracking-wide text-left'>Are there different difficulty levels?</h1>
                <p className='text-base text-left mt-2 text-gray-600'>The challenge lies in the limited number of guesses rather than specific difficulty levels, making it accessible for players of all skill levels.</p>
            </div>
        </div>
      </>
    );
  }
  
  export default Content;